import request from "@/config/serve.js";
import { MAXSIZE } from "@/common/constant/constant.js";

export function getLanguageList(params) {
  return request("get", "/language/all", {
    page: 1,
    size: MAXSIZE,
    ...params,
  });
}
// 查询预约管理
export function appointmentManage(params) {
  return request("post", "/appReserved/reservedApplyList", params);
}

//预约下架审核
export function outReviewInfo(params) {
  return request("post", "/app/outReviewInfo", params);
}
//预约审核
export function appointmentAudit(params) {
  return request("post", "/app/ReservedReviewInfo", params);
}
//预约审核 批量
export function appointmentAuditList(params) {
  return request("post", "/app/batchReviewInfo", params);
}
//预约 查看预约信息 Y
export function lookAppointmentInfo(params) {
  return request("get", "/app/selectReservedApply", params);
}
// 查询审核人员列表,type:页面（1信息审核列表，3预约审核列表，11预下架审核）
export function getAuditUsers(params) {
  return request("get", "/app/queryAuditUsers", { type: 3, ...params }, false);
}

// 查询国家、地区 Y
export function getAreaList(params) {
  return request("get", "/app/selectCountry", {
    page: 1,
    size: MAXSIZE,
    ...params,
  });
}
// 查询国家、地区 Y 出价阈值用到
export function countriesPromotionList(params) {
  return request("post", "/countriesPromotion/list", {
    page: 1,
    size: MAXSIZE,
    ...params,
  });
}
//内测管理列表
export function closedBetaList(params) {
  return request("post", "/appTest/queryTestList", params);
}
//内测管理 查看内测信息 Y
export function lookcloseBetaInfo(params) {
  return request("get", "/app/selectTestInfo", params);
}
//内测审核 Y
export function closeBetaAudit(params) {
  return request("post", "/app/TestReviewInfo", params);
}
//内测审核 批量
export function closeBetaAuditList(params) {
  return request("post", "/app/batchReviewTestInfo", params);
}

//首发列表  Y
export function startIngList(params) {
  return request("post", "/honorAppPrioritizedApply/prioritizedList", params);
}
//首发审核 Y
export function startAudit(params) {
  return request("post", "/honorAppPrioritizedApply/review", params);
}
//内测首发管理 查看首发信息 Y
export function lookstartingInfo(params) {
  return request("post", "/honorAppPrioritizedApply/detail", params);
}

// 查询角色列表 Y
export function getRoleList(params) {
  return request("post", "/role/queryList", params);
}
// 获取省市区
export function getCityList(params) {
  return request("get", "/apiGo/api/v1/app/manage/getRegionList", params);
}
// 获取开发者列表
export function getDeveloperUser(params) {
  return request("post", `/developerUser/getDeveloperUserList`, {
    ...params,
    identityType: 1,
  });
}
// 推广 创意
export function getIdeaAuditList(params) {
  return request("post", `/apiGo/api/v1/app/manage/getIdeaAuditList`, params);
}
export function getIdeaLog(params) {
  //历史记录
  return request("post", `/apiGo/api/v1/app/manage/getIdeaAuditLog`, params);
}
export function ideaAuditExec(params) {
  //审核
  return request("post", `/apiGo/api/v1/app/manage/ideaAuditExec`, params);
}
